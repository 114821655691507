/* 
* @include triangle within a pseudo element and add positioning properties (ie. top, left)
* $direction: up, down, left, right
*/
/* Vertical center children
	Example usage:
	HTML:
	<div class="container">
	  <div class="child">This div gets vertically centered :)</div>
	</div>
	SCSS:
	.container {
	  @include vertical-center-children(200px);
	}
*/
/* Margin center */
/*
    The provided mixins are intended to be used with the array-like variables

    .icon-home {
      @include sprite-width($icon-home);
    }

    .icon-email {
      @include sprite($icon-email);
    }

    Example usage in HTML:

    `display: block` sprite:
    <div class="icon-home"></div>

    To change `display` (e.g. `display: inline-block;`), we suggest using a common CSS class:

    // CSS
    .icon {
      display: inline-block;
    }

    // HTML
    <i class="icon icon-home"></i>
*/
/*
    The `sprites` mixin generates identical output to the CSS template
      but can be overridden inside of SCSS

    @include sprites($spritesheet-sprites);
*/
.checkbox label:after,
.radio label:after {
  content: '';
  display: table;
  clear: both;
}

.checkbox .cr,
.radio .cr {
  position: relative;
  display: inline-block;
  border: 1px solid #a9a9a9;
  border-radius: 0;
  width: 1.3em;
  height: 1.3em;
  float: left ;
  margin-right: 0.5em;
}

.radio .cr {
  border-radius: 50%;
}

.checkbox .cr .cr-icon,
.radio .cr .cr-icon {
  position: absolute;
  font-size: .8em;
  line-height: 0;
  top: 50%;
  right: 20%;
}

.radio .cr .cr-icon {
  margin-right: 0.04em;
}

.checkbox label input[type="checkbox"],
.radio label input[type="radio"] {
  display: none;
}

.checkbox label input[type="checkbox"] + .cr > .cr-icon,
.radio label input[type="radio"] + .cr > .cr-icon {
  opacity: 0;
  transition: all .1s ease-in;
}

.checkbox label input[type="checkbox"]:checked + .cr > .cr-icon,
.radio label input[type="radio"]:checked + .cr > .cr-icon {
  opacity: 1;
}

.checkbox label input[type="checkbox"]:disabled + .cr,
.radio label input[type="radio"]:disabled + .cr {
  opacity: .5;
}

body.compie-main-layout {
  background-color: #272c30;
}

body.compie-main-layout .page-content {
  margin-top: -68px !important;
  min-height: 100vh !important;
}

body.compie-main-layout .compie_page-content h3.page-title {
  background: #fff;
  margin-top: -25px;
  margin-left: -20px;
  margin-right: -20px;
  padding: 17px 20px;
  color: #000;
  font-weight: 500;
  font-size: 20px;
  border-bottom: 1px solid #E4E8EB;
}

body.compie-main-layout .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success {
  color: #fff;
  background: #ff6784;
}

body.compie-main-layout .bootstrap-switch .bootstrap-switch-handle-on.bootstrap-switch-success:hover {
  background-color: #ff7b94;
  border-color: #fb7f97;
}

body.compie-main-layout .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success {
  color: #fff;
  background: #ff6784;
}

body.compie-main-layout .bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-success:hover {
  background-color: #ff7b94;
  border-color: #fb7f97;
}

body.compie-main-layout .nav-tabs > li > a {
  color: #fb4165;
}

body.compie-main-layout .nav-tabs > li.active > a {
  color: #555555;
}

body.compie-main-layout .page-sidebar .page-sidebar-menu.page-sidebar-menu-compact > li > a > i,
body.compie-main-layout .page-sidebar-closed.page-sidebar-fixed .page-sidebar:hover .page-sidebar-menu.page-sidebar-menu-compact > li > a > i {
  text-align: center;
}

.page-sidebar-closed .page-sidebar .page-sidebar-menu.page-sidebar-menu-closed > li > a {
  padding-left: 16px !important;
}

.c_login {
  background: #26344B url("../../admin/images/back-lr.png") no-repeat;
  background-size: cover;
  height: 100vh !important;
  overflow-y: hidden;
}

.c_login .page-logo img {
  width: 80px;
}

.c_login .page-header.navbar .page-logo .logo-default {
  margin: 10px 0 0 34px;
}

.c_login .events table.table-bordered.dataTable tbody th, .c_login table.table-bordered.dataTable tbody td {
  max-width: 250px !important;
}

.c_login .page-lock {
  width: 380px;
  height: 450px;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.c_login .page-lock .lock-form .form-group .form-control {
  color: #98A7BD;
}

.c_login .page-lock .lock-avatar-block {
  margin-left: 20px;
}

.c_login .page-lock .page-logo {
  text-align: center;
}

.c_login .page-lock .page-logo img {
  height: 160px;
}

.c_login .page-lock .page-body {
  width: 100%;
  margin-top: 35px;
  -webkit-border-radius: 7px;
  -moz-border-radius: 7px;
  -ms-border-radius: 7px;
  -o-border-radius: 7px;
  border-radius: 7px;
}

.c_login .page-lock .lock-head {
  display: block;
  text-align: left;
  padding-top: 15px;
  padding-bottom: 15px;
  margin-bottom: -30px;
  font-size: 22px;
  font-weight: 400;
  color: #4db3a5;
  -webkit-border-radius: 7px 7px 0 0;
  -moz-border-radius: 7px 7px 0 0;
  -ms-border-radius: 7px 7px 0 0;
  -o-border-radius: 7px 7px 0 0;
  border-radius: 7px 7px 0 0;
}

.c_login .page-lock .lock-head img {
  width: 140px;
  margin: 0 auto;
  display: block;
}

.c_login .sphere-login {
  background-color: #00cde1;
  color: #fff;
}

.c_login .page-lock .lock-body {
  display: block;
  margin: 35px;
  overflow: hidden;
}

.c_login .page-lock .lock-body form {
  width: 100%;
}

.c_login .page-lock .lock-avatar {
  margin-top: 15px;
  height: 110px;
  -webkit-border-radius: 50% !important;
  -moz-border-radius: 50% !important;
  border-radius: 50% !important;
}

.c_login .page-lock .lock-form h4 {
  margin-top: 0px;
  color: #dbe2ea;
  font-size: 18px;
  font-weight: 400;
}

.c_login .page-lock .lock-form .form-group {
  margin-top: 20px;
}

.c_login .page-lock .lock-form .form-group .form-control {
  background-color: #f5fcff;
  border: none;
  text-align: center;
  font-size: 16px;
  height: 40px;
  color: #555555;
  font-weight: 500;
}

.c_login .loginFooter {
  position: absolute;
  bottom: 20px;
  left: 20px;
  z-index: 1;
  color: #fff;
}

.c_login .loginFooter img {
  border-right: 2px solid #434850;
  padding-right: 10px;
  margin-right: 10px;
}

.c_login .loginFooter span {
  color: #7b7d81;
}

.c_login .page-lock .lock-form .form-group .form-control::-moz-placeholder {
  color: #556376;
  opacity: 1;
}

.c_login .page-lock .lock-form .form-group .form-control:-ms-input-placeholder {
  color: #556376;
}

.c_login .page-lock .lock-form .form-group .form-control::-webkit-input-placeholder {
  color: #556376;
}

.c_login .page-lock .lock-form .form-group .form-control:focus {
  background-color: #f5fcff;
}

.c_login .page-lock .lock-form .form-actions {
  margin-top: 20px;
}

.c_login .page-lock .lock-form .form-actions .btn {
  width: 100%;
  font-weight: 600;
  padding: 10px;
  transition: all 0.4s;
}

.c_login .page-lock .lock-form .form-actions .btn:hover {
  background-color: rgba(67, 181, 180, 0.64);
  color: #fff;
}

.c_login .page-lock .lock-bottom {
  display: block;
  background-color: #323d4b;
  text-align: center;
  padding-top: 20px;
  color: #98A7BD;
  padding-bottom: 20px;
  -webkit-border-radius: 0 0 7px 7px;
  -moz-border-radius: 0 0 7px 7px;
  -ms-border-radius: 0 0 7px 7px;
  -o-border-radius: 0 0 7px 7px;
  border-radius: 0 0 7px 7px;
}

.c_login .page-lock .lock-bottom a {
  font-size: 14px;
  font-weight: 400;
  color: #638cac;
}

.c_login .page-lock .lock-bottom a:hover {
  color: #7ba2c0;
}

.c_login .page-lock .page-footer-custom {
  text-align: center;
  padding-top: 40px;
  color: #5f6e83;
}

.c_login .page-footer-inner a {
  color: #fff;
  font-weight: bolder;
}

.c_login .brand img {
  width: auto;
}

.c_login .mainBanner input {
  margin-bottom: 10px;
}

.c_login .compie-alerts {
  margin-top: 20px;
}

.c_login .compie-alerts .compieAlert {
  padding: 5px 10px;
  background-color: transparent;
  color: #fff;
  border: 1px solid #43b5b4;
  margin-bottom: 5px;
}

@media (max-width: 768px) {
  .c_login .page-lock {
    margin: 100px auto;
  }
  .c_login .page-header.navbar {
    background: #26344b;
  }
}

@media (max-width: 560px) {
  .c_login .page-lock {
    margin: 50px auto;
    width: 400px;
  }
  .c_login .page-lock .lock-body {
    margin: 30px;
  }
  .c_login .page-lock .lock-avatar {
    width: 120px;
    height: 120px;
    margin-top: 20px;
  }
  .c_login .page-lock .lock-form h4 {
    font-size: 16px;
  }
  .c_login .page-lock .lock-form .form-group .form-control {
    padding: 20px 20px;
    width: 170px;
  }
  .c_login .page-lock .lock-form .form-actions .btn-success {
    width: 170px;
    margin-bottom: 10px;
  }
}

@media (max-width: 420px) {
  .c_login .page-lock {
    margin: 30px auto;
    width: 280px;
  }
  .c_login .page-lock .lock-body {
    margin: 0px;
  }
  .c_login .page-lock .page-body {
    margin-top: 30px;
  }
  .c_login .page-lock .lock-avatar {
    margin-top: 20px;
  }
  .c_login .page-lock .lock-avatar-block {
    display: block;
    width: 100%;
    text-align: center;
    margin-bottom: 10px;
  }
  .c_login .page-lock .lock-form {
    padding-left: 20px;
  }
  .c_login .page-lock .lock-form h4 {
    font-size: 16px;
    text-align: center;
  }
  .c_login .page-lock .lock-form .form-group .form-control {
    padding: 20px 20px;
    width: 240px;
  }
  .c_login .page-lock .lock-form .form-actions .btn-success {
    width: 240px;
    margin-bottom: 20px;
  }
}

@media (min-width: 992px) {
  .c_header {
    z-index: 999 !important;
    width: 54px !important;
  }
}

.c_header.navbar .page-logo {
  background-color: #272c30 !important;
  border-bottom: 1px solid #3b444c;
  height: 60px;
  line-height: 60px;
  font-size: 0;
  position: relative;
  box-shadow: -3px 0 12px rgba(0, 0, 0, 0.8);
}

.c_header.navbar .page-logo .full-logo {
  position: absolute;
  left: 55px;
  top: 1px;
}

.c_header.navbar .page-logo .full-logo img {
  width: 115px;
}

.c_header.navbar .page-logo .logo-default {
  margin: 0;
}

.c_header.navbar .page-logo .sidebar-toggler {
  text-align: center;
  position: absolute;
  left: 9px;
  cursor: pointer;
}

@media (max-width: 991px) {
  .c_header.navbar .page-logo {
    display: none;
  }
}

.page-bar {
  background: none;
}

.page-bar .page-bar_buttons {
  display: inline-block;
  float: right ;
}

.page-bar .page-breadcrumb.c_breadcrumb li a {
  font-size: 14px;
  color: #272c30;
}

.c_side-menu ul.page-sidebar-menu-closed.page-sidebar-menu {
  z-index: 5;
  position: fixed;
}

.c_side-menu ul.page-sidebar-menu-closed.page-sidebar-menu > li.active > a,
.c_side-menu ul.page-sidebar-menu-closed.page-sidebar-menu > li.active.open > a {
  background-color: #272c30;
  border: none;
}

.c_side-menu ul.page-sidebar-menu-closed.page-sidebar-menu > li.active > a:hover,
.c_side-menu ul.page-sidebar-menu-closed.page-sidebar-menu > li.active.open > a:hover {
  background-color: #272c30;
}

.c_side-menu ul.page-sidebar-menu-closed.page-sidebar-menu li.nav-item:hover a > i {
  color: #fff;
  font-weight: 500;
}

.c_side-menu ul.page-sidebar-menu-closed.page-sidebar-menu.bottom li.nav-item:hover {
  width: 54px !important;
  box-shadow: none !important;
}

.c_side-menu ul.page-sidebar-menu-closed.page-sidebar-menu.bottom li.nav-item:hover a.nav-link > span.title {
  display: none !important;
}

.c_side-menu .page-sidebar {
  background-color: #272c30;
  position: fixed;
  z-index: 5;
}

.c_side-menu ul.page-sidebar-menu {
  background-color: #272c30;
}

.c_side-menu ul.page-sidebar-menu > li > a {
  border: none;
  font-weight: 500;
}

.c_side-menu ul.page-sidebar-menu > li > a:hover {
  background-color: #272c30;
}

.c_side-menu ul.page-sidebar-menu > li.active > a,
.c_side-menu ul.page-sidebar-menu > li.active.open > a {
  background-color: #2a61c6;
  border: none;
}

.c_side-menu ul.page-sidebar-menu > li.active > a:hover,
.c_side-menu ul.page-sidebar-menu > li.active.open > a:hover {
  background-color: #2a61c6;
}

.c_side-menu ul.page-sidebar-menu > li.active > a > i[class^="icon-"],
.c_side-menu ul.page-sidebar-menu > li.active.open > a > i[class^="icon-"] {
  color: #fff;
}

.c_side-menu ul.page-sidebar-menu li > a > i[class^="icon-"] {
  color: #5f727f;
  font-size: 18px;
  margin-left: 0;
}

.c_side-menu ul.page-sidebar-menu li.active a i {
  color: #fff;
}

.c_side-menu ul.page-sidebar-menu li:hover > a > i {
  color: #fff;
}

.c_side-menu .page-sidebar-menu.bottom {
  position: absolute;
  bottom: 0;
}

@media (min-width: 768px) {
  .c_side-menu .navbar-collapse.collapse {
    min-height: calc(100vh - 68px) !important;
  }
}

.page-sidebar .page-sidebar-menu > li {
  transition: all .2s ease-in-out;
}

.page-sidebar .page-sidebar-menu > li > a {
  color: #dce2e6;
}

.page-sidebar .page-sidebar-menu > li > a > i {
  color: #5f727f;
  font-size: 18px;
  margin-left: 0;
}

.page-sidebar .page-sidebar-menu > li:hover > a {
  background: #37424a;
  color: #fafbfb;
}

.page-sidebar .page-sidebar-menu > li:hover > a > i {
  color: #5f727f;
}

.page-sidebar .page-sidebar-menu .sub-menu {
  border-radius: 0 0 4px 4px;
}

.page-sidebar .page-sidebar-menu .sub-menu li {
  box-shadow: 5px 5px rgba(48, 48, 48, 0.2);
}

.uneditable-input {
  min-width: 10px;
}

.pull-right.plus-button {
  margin-left: 20px;
}

.row.save-button {
  margin-right: 20px;
}

body.file-manager-popup {
  background: #fff;
}

body.file-manager-popup .page-content {
  min-height: 0 !important;
}

.modImages {
  margin-top: 20px;
  width: 99%;
  margin: auto;
}

.modImages .top {
  position: relative;
}

.modImages ul {
  margin-bottom: 0 !important;
  -moz-padding-start: 0px;
  -webkit-padding-start: 0px;
  -khtml-padding-start: 0px;
  -o-padding-start: 0px;
  padding-start: 0px;
  padding: 0px;
}

.modImages ul li:before {
  content: initial;
}

.modImages .path {
  display: inline-block;
  background: #F7F7F7;
  padding: 10px 20px;
  border-top: 3px solid #1bbc9b;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  font-size: 14px;
  margin-bottom: -1px;
}

.modImages .path a {
  color: #428bca;
}

.modImages .path a:hover {
  color: #2a6496;
  text-decoration: none;
}

.modImages .buttons {
  position: absolute;
  right: 0;
  bottom: 5px;
  display: inline-block;
}

.modImages .buttons .btn {
  margin: 0;
}

.modImages .items {
  width: 100%;
  border-top: 1px solid #ddd;
  padding-top: 0px;
}

.modImages .items .background {
  background: #F7F7F7;
  margin: 0px 0px;
  padding: 10px 0 0 0;
  border-right: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
}

.modImages .items .background .upload-info-details {
  margin-left: 20px;
  margin-right: 0;
  line-height: 22px;
}

.modImages .items .background .no-files {
  width: 50%;
  margin: 20px auto;
  font-size: 2em;
  color: #7d96bd;
  border-radius: 8px;
  text-align: center;
}

.modImages .items .background .actions {
  margin: 0px 15px 10px 15px !important;
  background: white;
  border: 1px solid #ddd;
  padding: 5px;
}

.modImages .items .background .actions .search {
  padding-left: -10px !important;
  margin-top: -15px;
  float: right ;
}

.modImages .items .background .actions .search .form-group {
  margin-top: 17px;
  margin-bottom: 2px;
}

.modImages .items .background .actions .part1 {
  padding: 7px 5px 7px 0px;
  line-height: 28px;
  float: left;
}

.modImages .items .background .actions .part1 span {
  font-size: 14px;
  color: #626262;
  font-weight: bold;
}

.modImages .items .background .actions .part1 .links {
  font-size: 14px;
  display: inline-block;
  color: #626262;
  font-weight: bold;
}

.modImages .items .background .actions .part1 .links span {
  font-weight: normal !important;
}

.modImages .items .background .actions .part1 .acts .moveOption {
  display: none;
}

.modImages .items .background .actions .part1 .acts .moveOption #transferToFolder {
  width: 200px;
  min-width: 200px;
  display: inline-block;
  padding: 3px 3px !important;
  height: 28px !important;
}

.modImages .items .background .actions .part1 .acts .moveOption #transferToFolder:focus {
  width: auto;
}

.modImages .items .background #cntImgsChecked {
  display: none;
}

.modImages .items .background .files .template-download .preview img {
  max-height: 120px;
  border-radius: 5px;
}

.modImages .items .background .actionsFromIframe {
  margin: 0px 15px 0px 15px !important;
  font-size: 14px;
  color: #626262;
  font-weight: bold;
}

.modImages .items .background .row {
  margin: 5px;
}

.modImages .items .background .thumbnail {
  height: 200px;
  width: 100%;
  padding: 12px 12px 0 14px;
  position: relative;
}

.modImages .items .background .thumbnail .folder {
  /* This will enable the 3D effect. Decrease this value
           * to make the perspective more pronounced: */
  -webkit-perspective: 800px;
  -moz-perspective: 800px;
  perspective: 800px;
  z-index: 0;
  width: 120px;
  max-width: 90%;
  height: 120px;
  margin: 20px auto 0 auto;
}

.modImages .items .background .thumbnail .folder div {
  width: 135px;
  height: 100px;
  max-width: 100%;
  background-color: #93bad8;
  /* Enabling 3d space for the transforms */
  -webkit-transform-style: preserve-3d;
  -moz-transform-style: preserve-3d;
  transform-style: preserve-3d;
  /* Enabling a smooth animated transition */
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  transition: 0.5s;
  /* Disable text seleltion on the folder */
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
  position: absolute;
  margin-right: -75px;
}

.modImages .items .background .thumbnail .folder .front {
  border-radius: 5px 5px 0 0;
  -webkit-transform: rotateX(-30deg);
  transform: rotateX(-30deg);
  -webkit-transform-origin: 50% 100%;
  transform-origin: 50% 100%;
  background-image: -webkit-linear-gradient(top, #a8cbe6 0%, #6c9dc0 85%, #628faf 100%);
  background-image: linear-gradient(top, #93bad8 0%, #6c9dc0 85%, #628faf 100%);
  box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1), 0 1px rgba(255, 255, 255, 0.35) inset;
  z-index: 10;
  font: bold 14px sans-serif;
  color: #3e769e;
  text-align: center;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.62);
  line-height: 115px;
}

.modImages .items .background .thumbnail .folder .back {
  background-image: -webkit-linear-gradient(top, #93bad8 0%, #89afcc 10%, #5985a5 60%);
  background-image: -moz-linear-gradient(top, #93bad8 0%, #89afcc 10%, #5985a5 60%);
  background-image: linear-gradient(top, #93bad8 0%, #89afcc 10%, #5985a5 60%);
  border-radius: 0 5px 0 0;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.15);
}

.modImages .items .background .thumbnail .folder .back:before {
  content: '';
  width: 60px;
  height: 10px;
  border-radius: 4px 4px 0 0;
  background-color: #93bad8;
  position: absolute;
  top: -10px;
  left: 0px;
  box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.15);
}

.modImages .items .background .thumbnail .folder .back:after {
  content: '';
  width: 100%;
  height: 4px;
  border-radius: 5px;
  position: absolute;
  bottom: 5px;
  left: 0px;
  box-shadow: 0 4px 8px #333;
}

.modImages .items .background .thumbnail .folder:hover .front {
  -moz-transform: rotateX(-50deg);
  -webkit-transform: rotateX(-50deg);
  transform: rotateX(-50deg);
}

.modImages .items .background .thumbnail a:hover {
  text-decoration: none;
}

.modImages .items .background .thumbnail .info {
  position: absolute;
  left: 7px;
  top: 0px;
  padding: 4px 1px 0px 3px;
  background: white;
  border-radius: 50% !important;
  font-size: 20px;
}

.modImages .items .background .thumbnail .info a {
  color: #6089a7;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.modImages .items .background .thumbnail .info a:hover {
  color: #8eb2cc;
}

.modImages .items .background .thumbnail .info_open {
  position: absolute;
  left: 3%;
  top: 3%;
  width: 94%;
  height: 94%;
  opacity: 0.98;
  background: rgba(247, 247, 247, 0.9);
  z-index: 99;
  display: none;
}

.modImages .items .background .thumbnail .info_open .text {
  opacity: 1;
  color: #738998;
  z-index: 9999;
  font-size: 13px;
  font-weight: bold;
  padding: 24px 10px 0 10px;
  text-align: center;
  line-height: 34px;
}

.modImages .items .background .thumbnail .info_btnClose {
  position: absolute;
  left: 9px;
  top: 7px;
  color: gray;
  z-index: 999;
  display: none;
}

.modImages .items .background .thumbnail .info_btnClose .close {
  height: 20px;
  width: 20px;
  margin-left: 0px;
  text-indent: 0;
  background: none !important;
}

.modImages .items .background .activeThumb {
  border: 2px solid #428bca;
}

.modImages .items .background .activeThumb:hover {
  border: 2px solid #428bca !important;
}

.modImages .items .background .thumbnail .image {
  height: 121px;
  margin: 19px 0 0 0;
  text-align: center;
}

.modImages .items .background .thumbnail .image .typeFile {
  position: absolute;
  top: 0px;
  right: 0px;
  font-size: 12px;
  color: #626262;
  font-weight: bold;
  background: #ddd;
  padding: 0px 6px 2px 6px;
  opacity: 1;
}

.modImages .items .background .thumbnail .folder {
  cursor: pointer;
}

.modImages .items .background .thumbnail .folder .folderClose {
  width: 110px !important;
  display: inline-block;
  margin-top: -20px;
}

.modImages .items .background .thumbnail .folder .folderOpen {
  width: 110px !important;
  display: none;
  margin-top: -20px;
}

.modImages .items .background .thumbnail a {
  color: #626262;
  font-weight: normal;
}

.modImages .items .background .thumbnail a:hover {
  color: #626262;
  font-weight: normal;
}

.modImages .items .background .thumbnail img {
  max-height: 120px;
  width: auto !important;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
}

.modImages .items .background .thumbnail:hover {
  border: 1px solid #428bca;
}

.modImages .items .background .options .selectedImg {
  background: url("../images/file-manager/checkbox7_checked.png ") no-repeat bottom left !important;
  float: left;
  height: 42px;
  width: 43px;
  cursor: pointer;
  margin: -2px 0 0 -14px !important;
}

.modImages .items .background .options .checkbox {
  background: url("../images/file-manager/checkbox7.png ") no-repeat bottom left;
  float: left;
  height: 42px;
  width: 43px;
  cursor: pointer;
  margin: 1px 0 0 -16px !important;
}

.modImages .items .background .options .checkImg {
  display: none;
}

.modImages .items .background .options .inputEditCredit, .modImages .items .background .options .inputEditName {
  width: calc(100% - 42px);
  border: 1px solid white;
  padding: 3px 5px;
  max-width: 80%;
  float: right;
  font-size: 14px;
  margin-top: 3px;
  transition: border linear 0.2s, box-shadow linear 0.2s;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
  outline: none;
}

.modImages .items .background .options .inputEditCredit:hover, .modImages .items .background .options .inputEditName:hover {
  border: 1px dashed #B7B7B7;
}

.modImages .items .background .options .inputEditCredit:focus, .modImages .items .background .options .inputEditName:focus {
  border: 1px solid #B7B7B7;
}

.modImages .items .background .bottom {
  background: #e3e3e3;
  height: 20px;
  width: 100%;
  padding-right: 10px;
}

.modImages .items .background .options .disableInput {
  background: none !important;
  border: 1px dashed transparent !important;
  box-shadow: 0 0px 0px transparent inset !important;
}

.modImages .items .chooseOneFile {
  position: absolute;
  top: 4px;
  left: 50%;
}

.modImages .items .chooseOneFile .btn {
  position: relative;
  left: -50%;
  padding: 4px 8px !important;
  font-size: 12px;
  font-weight: bold;
  border-radius: 3px !important;
}

@media (max-width: 560px) {
  .modImages .path {
    min-height: 80px;
  }
  .modImages ul {
    -webkit-padding-start: 0px;
    margin-bottom: 0 !important;
    margin-right: 122px;
  }
  .modImages .buttons {
    width: 116px;
  }
  .modImages .buttons .first {
    margin-bottom: 5px !important;
  }
}

#file-manager-modal {
  display: block;
  background: #fff;
  height: 90vh;
  width: 1024px;
  margin: 0 auto;
}

#file-manager-modal .modal-title {
  color: #648aa9;
}

#file-manager-modal .modal-body {
  height: 93%;
}

#file-manager-modal .modal-body iframe {
  height: 95%;
  width: 100%;
}

#fileupload .text-danger {
  color: #fff;
}

#files-crop {
  /* Old Vertions: */
}

#files-crop .mainCutImg {
  display: inline-block;
  border: 2px solid #000000;
  margin: 5px 5px 0 0;
}

#files-crop .beforeImgBox, #files-crop .afterImgBox {
  width: 250px;
  margin-bottom: 10px;
  text-align: right;
}

#files-crop .beforeImgBox .imgBlock {
  border: 2px solid #000000;
  height: 250px;
  width: 250px;
  box-sizing: initial;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAdSURBVChTYziDAf5jgKGrCEojAahaJDBEFZ05AwDtzYNYOoLD6AAAAABJRU5ErkJggg==);
}

#files-crop .beforeImgBox .imgBlock img {
  max-height: 250px;
  max-width: 250px;
}

#files-crop .afterImgBox .imgBlock {
  border: 2px solid #000000;
  height: 250px;
  width: 250px;
  box-sizing: initial;
  display: table-cell;
  vertical-align: middle;
  text-align: center;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAdSURBVChTYziDAf5jgKGrCEojAahaJDBEFZ05AwDtzYNYOoLD6AAAAABJRU5ErkJggg==);
}

#files-crop .afterImgBox .imgBlock div {
  margin: auto;
  direction: ltr;
  overflow: hidden;
}

#files-crop .oldImagesBlock {
  background: gainsboro;
  margin-top: 20px;
  padding: 0 10px;
}

#files-crop .noOldYet {
  margin-right: 30px;
  margin-bottom: 30px;
}

#files-crop .oldImageBox .options {
  position: absolute;
  left: 0;
  top: 0;
}

#files-crop .oldImageBox .imgBlock {
  width: 250px;
  height: 250px;
  display: table-cell;
  text-align: center;
  vertical-align: middle;
}

#files-crop .oldImageBox .imgBlock img {
  max-width: 250px;
  max-height: 250px;
}

.file-multi-container .ui-state-highlight {
  border: 1px dotted #dddddd;
  height: 294px;
  display: inline-block;
  vertical-align: top;
  margin: 10px 10px 10px 10px;
  width: 213px;
  border-radius: 3px;
}

.file-multi-container .file-multi-options button {
  display: inline;
  border: 2px solid #3d4a53;
  border-radius: 9px;
  margin: 0px 2px 10px 0;
  color: #3d4a53;
  -webkit-transition: all 0.3s;
  transition: all 0.3s;
  background: #fff;
  font-size: 14px;
  padding: 1px 5px;
  font-weight: 700;
}

.file-multi-container .file-multi-options button:hover {
  border: 2px solid #988ee6;
  color: #988ee6;
}

.file-multi-container .file-multi-options button:focus {
  outline: 0 !important;
}

.file-multi-container .file-multi-options button.red-button:hover {
  border: 2px solid #d05e5e;
  color: #d05e5e;
}

.fileinput {
  margin: 5px;
  min-width: 188px;
}

.fileinput.multi {
  cursor: move;
}

.fileinput .thumbnail {
  width: 100%;
  height: 100%;
  position: relative;
}

.fileinput .thumbnail .options {
  position: absolute;
  top: 10px;
  left: 10px;
}

.fileinput .thumbnail .options ul {
  list-style-type: none;
  margin: 0;
  font-size: 17px;
  padding: 0;
}

.fileinput .thumbnail .options ul li {
  cursor: pointer;
  display: inline;
  float: left;
  border: 2px solid #3d4a53;
  border-radius: 9px;
  margin: 0 2px;
  padding: 0 2px;
  color: #3d4a53;
  -webkit-transition: all 0.3s;
  -moz-transition: all 0.3s;
  -ms-transition: all 0.3s;
  -o-transition: all 0.3s;
  transition: all 0.3s;
}

.fileinput .thumbnail .options ul li.remove-red:hover {
  border: 2px solid #d05e5e;
  color: #d05e5e;
}

.fileinput .thumbnail .options ul li:hover {
  border: 2px solid #988ee6;
  color: #988ee6;
}

.fileinput .thumbnail .image-input-placeholder {
  width: 120px;
  height: 120px;
  border-radius: 3px;
  margin: 40px auto 10px auto;
}

html {
  min-height: 100%;
}

.clear {
  clear: both;
}

.tab-pane {
  display: none;
}

.tab-pane .tab-pane.active {
  display: block;
}

.page-header.navbar .page-logo .logo-default {
  margin-top: 9px;
  height: 52px;
  margin-left: 40px !important;
}

.custom-info-block {
  margin-top: 3px;
  margin-bottom: 10px;
  background: #EEF1F5;
  float: left ;
  padding: 10px 0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-right: 0px;
}

.list-portfolio {
  padding: 0px 15px;
}

.list-portfolio .items {
  margin-bottom: 20px;
}

.list-portfolio .item-block {
  background: #EEF1F5;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  height: 300px;
  overflow-y: auto;
  overflow-x: hidden;
}

.list-portfolio .item-block .fixOverlayDiv {
  height: 300px;
  position: relative;
  text-align: center;
}

.list-portfolio .item-block .fixOverlayDiv img {
  position: absolute;
  transform: translate(0, -50%);
  top: 50%;
  display: block;
  margin: 0 auto;
}

.list-portfolio .item-block h3 {
  margin: 0;
}

.list-portfolio .item-block .infoside {
  padding-top: 10px;
  padding-bottom: 20px;
}

.tabbable-line > .tab-content.padding_table {
  padding-right: 15px;
  padding-left: 15px;
}

.portlet.box > .portlet-body {
  padding: 15px !important;
}

.group_300, .group_250, .group_200, .group_150, .group_130, .group_100, .group_80, .group_50 {
  width: 100%;
  font-size: 0;
}

.group_300 > label, .group_250 > label, .group_200 > label, .group_150 > label, .group_130 > label, .group_100 > label, .group_80 > label, .group_50 > label {
  vertical-align: top;
  font-size: 14px;
  display: inline-block;
}

.group_300 > label.middle, .group_250 > label.middle, .group_200 > label.middle, .group_150 > label.middle, .group_130 > label.middle, .group_100 > label.middle, .group_80 > label.middle, .group_50 > label.middle {
  vertical-align: middle;
  margin-bottom: 10px;
}

.group_300 .input_container, .group_250 .input_container, .group_200 .input_container, .group_150 .input_container, .group_130 .input_container, .group_100 .input_container, .group_80 .input_container, .group_50 .input_container {
  display: inline-block;
}

.group_300 .input_container .without_padding:last-child, .group_250 .input_container .without_padding:last-child, .group_200 .input_container .without_padding:last-child, .group_150 .input_container .without_padding:last-child, .group_130 .input_container .without_padding:last-child, .group_100 .input_container .without_padding:last-child, .group_80 .input_container .without_padding:last-child, .group_50 .input_container .without_padding:last-child {
  padding-left: 15px !important;
}

.group_300 .input_container .without_padding:first-child, .group_250 .input_container .without_padding:first-child, .group_200 .input_container .without_padding:first-child, .group_150 .input_container .without_padding:first-child, .group_130 .input_container .without_padding:first-child, .group_100 .input_container .without_padding:first-child, .group_80 .input_container .without_padding:first-child, .group_50 .input_container .without_padding:first-child {
  padding-right: 15px !important;
}

.group_300 > label {
  width: 300px;
}

.group_300 .input_container {
  width: calc(100% - 300px);
}

.group_250 > label {
  width: 250px;
}

.group_250 .input_container {
  width: calc(100% - 250px);
}

.group_200 > label {
  width: 200px;
}

.group_200 .input_container {
  width: calc(100% - 200px);
}

.group_150 > label {
  width: 150px;
}

.group_150 .input_container {
  width: calc(100% - 150px);
}

.group_130 > label {
  width: 130px;
}

.group_130 .input_container {
  width: calc(100% - 130px);
}

.group_100 > label {
  width: 100px;
}

.group_100 .input_container {
  width: calc(100% - 100px);
}

.group_80 > label {
  width: 80px;
}

.group_80 .input_container {
  width: calc(100% - 80px);
}

.group_50 > label {
  width: 50px;
}

.group_50 .input_container {
  width: calc(100% - 50px);
}

.without_padding {
  padding-left: 0;
  padding-right: 0;
}

.without_padding:last-child {
  padding-left: 15px !important;
}

.without_padding:first-child {
  padding-right: 15px !important;
}

.info-hint {
  font-size: 13px;
  color: #6565ff;
  text-shadow: 1px 1px #ccc3ff;
}

.export-btn {
  margin-top: 50px;
}

.btn-pink {
  color: #fff;
  background-color: #ff6784;
  border-color: #ff6784;
}

.btn-pink:hover {
  color: #fff;
  background-color: #ff7b94;
  border-color: #fb7f97;
}

.btn-pink.active {
  color: #fff;
  background-color: #ea617c;
  border-color: #f3778f;
}

.btn-outline {
  background-color: transparent;
  color: inherit;
  transition: all .5s;
}

.btn-primary.btn-outline {
  color: #428bca;
}

.btn-primary.btn-outline:hover {
  color: #fff;
}

.btn-success.btn-outline {
  color: #5cb85c;
}

.btn-success.btn-outline:hover {
  color: #fff;
}

.btn-info.btn-outline {
  color: #5bc0de;
}

.btn-info.btn-outline:hover {
  color: #fff;
}

.btn-pink.btn-outline {
  color: #ff6784;
}

.btn-pink.btn-outline.active {
  color: #fff;
}

.btn-pink.btn-outline:hover {
  color: #fff;
}

.btn-warning.btn-outline {
  color: #f0ad4e;
}

.btn-warning.btn-outline:hover {
  color: #fff;
}

.btn-danger.btn-outline {
  color: #d9534f;
}

.btn-danger.btn-outline:hover {
  color: #fff;
}

.form-group div[class^=col] > .form-group:last-child {
  border: none;
}
