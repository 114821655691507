// Utils (helpers, fucntions, mixins and etc..)
@import "utils/typography";
@import "utils/text";
@import "utils/retina";
@import "utils/visually-hidden";
@import "utils/shapes";
@import "utils/elements-middle";
@import "utils/sprites";
@import "utils/buttons";
@import "utils/radios";

// Components
@import "compie";
@import 'global/login.scss';
@import 'global/header.scss';
@import 'global/side-menu.scss';
@import 'pages/_conf_images';
@import "pages/_file_manager";

//colors
$color_royal_blue_approx: #6565ff;
$color_melrose_approx: #ccc3ff;
$color_boston_blue_approx: #428bca;
$color_fern_approx: #5cb85c;
$color_viking_approx: #5bc0de;
$color_brink_pink_approx: #ff6784;
$color_casablanca_approx: #f0ad4e;
$color_chestnut_rose_approx: #d9534f;
$white: #fff;
$color_froly_approx: #f3778f;


html {
  min-height: 100%;
}

.clear {
  clear: both;
}

.tab-pane {
  display: none;
  .tab-pane.active {
    display: block;
  }
}

// side bar logo
.page-header.navbar .page-logo .logo-default {
  margin-top: 9px;
  height: 52px;
  margin-left: 40px !important;
}

@include rtl {
  .mt-checkbox > span:after {
    transform: rotateY(180deg) rotateZ(-30deg);
  }

  .dropdown-menu.pull-right {
    right: 0;
    left: auto;
  }

  .dropdown > .dropdown-menu.pull-right:before, .dropdown-toggle > .dropdown-menu.pull-right:before, .btn-group > .dropdown-menu.pull-right:before {
    left: auto;
    right: 9px;
  }

  .dropdown > .dropdown-menu.pull-right:after, .dropdown-toggle > .dropdown-menu.pull-right:after, .btn-group > .dropdown-menu.pull-right:after {
    left: auto;
    right: 10px;
  }
}

// Custom information block
.custom-info-block {
  margin-top: 3px;
  margin-bottom: 10px;
  background: #EEF1F5;
  @include float(left);
  padding: 10px 0;
  border-radius: 5px;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  margin-right:0px;
}

// list portfolio
.list-portfolio {
  padding: 0px 15px;

  .items{
    margin-bottom: 20px;
  }

  .item-block {
    background: #EEF1F5;
    border-radius: 5px;
    -moz-border-radius: 5px;
    -webkit-border-radius: 5px;
    height:300px;
    overflow-y: auto;
    overflow-x: hidden;

    .fixOverlayDiv {
      height: 300px;
      position: relative;
      text-align: center;

      img {
        position: absolute;
        transform: translate(0, -50%);
        top: 50%;
        display: block;
        margin: 0 auto;
      }
    }

    h3 {
      margin: 0;
    }

    .infoside {
      padding-top: 10px;
      padding-bottom: 20px;
    }
  }
}

.tabbable-line > .tab-content.padding_table {
  padding-right: 15px;
  padding-left: 15px;
}

.portlet.box > .portlet-body {
  padding: 15px !important;
}

.group_300, .group_250, .group_200, .group_150, .group_130, .group_100, .group_80, .group_50 {
  width: 100%;
  font-size: 0;

  & > label {
    vertical-align: top;
    font-size: 14px;
    display: inline-block;
    &.middle {
      vertical-align: middle;
      margin-bottom: 10px;
    }
  }
  .input_container {
    display: inline-block;
    .without_padding:last-child {
      @include ltr {
        padding-left: 15px !important;
      }
      @include rtl {
        padding-right: 15px !important;
      }
    }
    .without_padding:first-child {
      @include ltr {
        padding-right: 15px !important;
      }
      @include rtl {
        padding-left: 15px !important;
      }
    }
  }
}

.group_300 {
  & > label {
    width: 300px;
  }
  .input_container {
    width: calc(100% - 300px);
  }
}
.group_250 {
  & > label {
    width: 250px;
  }
  .input_container {
    width: calc(100% - 250px);
  }
}

.group_200 {
  & > label {
    width: 200px;
  }
  .input_container {
    width: calc(100% - 200px);
  }
}

.group_150 {
  & > label {
    width: 150px;
  }
  .input_container {
    width: calc(100% - 150px);
  }
}

.group_130 {
  & > label {
    width: 130px;
  }
  .input_container {
    width: calc(100% - 130px);
  }
}

.group_100 {
  & > label {
    width: 100px;
  }
  .input_container {
    width: calc(100% - 100px);
  }
}

.group_80 {
  & > label {
    width: 80px;
  }
  .input_container {
    width: calc(100% - 80px);
  }
}

.group_50 {
  & > label {
    width: 50px;
  }
  .input_container {
    width: calc(100% - 50px);
  }
}

.without_padding {
  padding-left: 0;
  padding-right: 0;
}

.without_padding:last-child {
  @include ltr {
    padding-left: 15px !important;
  }
  @include rtl {
    padding-right: 15px !important;
  }
}
.without_padding:first-child {
  @include ltr {
    padding-right: 15px !important;
  }
  @include rtl {
    padding-left: 15px !important;
  }
}


.info-hint {
  font-size: 13px;
  color: $color_royal_blue_approx;
  //Instead of the line below you could use @include text-shadow($shadow-1, $shadow-2, $shadow-3, $shadow-4, $shadow-5, $shadow-6, $shadow-7, $shadow-8, $shadow-9, $shadow-10)
  text-shadow: 1px 1px $color_melrose_approx;
}
.export-btn {
  margin-top: 50px;
}

.btn-pink {
  color: #fff;
  background-color: #ff6784;
  border-color: #ff6784;
  &:hover {
    color: #fff;
    background-color: #ff7b94;
    border-color: #fb7f97;
  }
  &.active {
    color: #fff;
    background-color: #ea617c;
    border-color: #f3778f;
  }
}

.btn-outline {
  background-color: transparent;
  color: inherit;
  //Instead of the line below you could use @include transition($transition-1, $transition-2, $transition-3, $transition-4, $transition-5, $transition-6, $transition-7, $transition-8, $transition-9, $transition-10)
  transition: all .5s;
}

.btn-primary.btn-outline {
  color: $color_boston_blue_approx;
  &:hover {
    color: $white;
  }
}
.btn-success.btn-outline {
  color: $color_fern_approx;
  &:hover {
    color: $white;
  }
}
.btn-info.btn-outline {
  color: $color_viking_approx;
  &:hover {
    color: $white;
  }
}
.btn-pink.btn-outline {
  &.active {
    color: $white;
  }
  color: $color_brink_pink_approx;
  &:hover {
    color: $white;
  }
}
.btn-warning.btn-outline {
  color: $color_casablanca_approx;
  &:hover {
    color: $white;
  }
}
.btn-danger.btn-outline {
  color: $color_chestnut_rose_approx;
  &:hover {
    color: $white;
  }
}

.form-group {
  div[class^=col] {
    & > .form-group {
      &:last-child {
        border: none;
      }
    }
  }
}