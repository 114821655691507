body {
  &.file-manager-popup {
    background: #fff;
    .page-content {
      min-height: 0 !important;
    }
  }
}

.modImages {
  margin-top: 20px;
  width: 99%;
  margin: auto;
  .top {
    position: relative;
  }
  ul {
    margin-bottom: 0 !important;
    -moz-padding-start: 0px;
    -webkit-padding-start: 0px;
    -khtml-padding-start: 0px;
    -o-padding-start: 0px;
    padding-start: 0px;
    padding: 0px;
    li {
      &:before {
        content: initial;
      }
    }
  }
  .path {
    display: inline-block;
    background: #F7F7F7;
    padding: 10px 20px;
    border-top: 3px solid #1bbc9b;
    border-right: 1px solid #ddd;
    border-left: 1px solid #ddd;
    font-size: 14px;
    margin-bottom: -1px;
    a {
      color: #428bca;
      &:hover {
        color: #2a6496;
        text-decoration: none;
      }
    }
  }
  .buttons {
    position: absolute;
    @include right(0);
    bottom: 5px;
    display: inline-block;
    .btn {
      margin: 0;
    }
  }
  .items {
    width: 100%;
    border-top: 1px solid #ddd;
    padding-top: 0px;
    .background {
      background: #F7F7F7;
      margin: 0px 0px;
      padding: 10px 0 0 0;
      border-right: 1px solid #ddd;
      border-left: 1px solid #ddd;
      border-bottom: 1px solid #ddd;
      .upload-info-details {
        @include margin-left(20px);
        @include margin-right(0);
        line-height: 22px;
      }
      .no-files {
        width: 50%;
        margin: 20px auto;
        font-size: 2em;
        color: #7d96bd;
        border-radius: 8px;
        text-align: center;
      }
      .actions {
        margin: 0px 15px 10px 15px !important;
        background: white;
        border: 1px solid #ddd;
        padding: 5px;
        .search {
          padding-left: -10px !important;
          margin-top: -15px;
          @include float(right);
          .form-group {
            margin-top: 17px;
            margin-bottom: 2px;
          }
        }
        .part1 {
          padding: 7px 5px 7px 0px;
          line-height: 28px;
          float: left;
          span {
            font-size: 14px;
            color: #626262;
            font-weight: bold;
          }
          .links {
            font-size: 14px;
            display: inline-block;
            color: #626262;
            font-weight: bold;
            span {
              font-weight: normal !important;
            }
          }
          .acts {
            .moveOption {
              display: none;
              #transferToFolder{
                width: 200px;
                min-width: 200px;
                display: inline-block;
                padding: 3px 3px !important;
                height: 28px !important;
                &:focus {
                  width:auto;
                }
              }
            }
          }
        }
      }
      #cntImgsChecked {
        display: none;
      }
      .files {
        .template-download{
          .preview {
            img {
              max-height: 120px;
              border-radius: 5px;
            }
          }
        }
      }
      .actionsFromIframe {
        margin: 0px 15px 0px 15px !important;
        font-size: 14px;
        color: #626262;
        font-weight: bold;
      }
      .row {
        margin: 5px;
      }
      .thumbnail {
        height: 200px;
        width: 100%;
        padding: 12px 12px 0 14px;
        position: relative;
        .folder {
          /* This will enable the 3D effect. Decrease this value
           * to make the perspective more pronounced: */
          -webkit-perspective: 800px;
          -moz-perspective: 800px;
          perspective: 800px;
          z-index: 0;
          width: 120px;
          max-width: 90%;
          height: 120px;
          margin: 20px auto 0 auto;
          div {
            width: 135px;
            height: 100px;
            max-width: 100%;
            background-color: #93bad8;
            /* Enabling 3d space for the transforms */
            -webkit-transform-style: preserve-3d;
            -moz-transform-style: preserve-3d;
            transform-style: preserve-3d;
            /* Enabling a smooth animated transition */
            -webkit-transition: 0.5s;
            -moz-transition: 0.5s;
            transition: 0.5s;
            /* Disable text seleltion on the folder */
            -webkit-user-select: none;
            -moz-user-select: none;
            user-select: none;
            position: absolute;
            @include margin-right(-75px);
          }
          .front {
            border-radius: 5px 5px 0 0;
            -webkit-transform: rotateX(-30deg);
            transform: rotateX(-30deg);
            -webkit-transform-origin: 50% 100%;
            transform-origin: 50% 100%;
            background-image: -webkit-linear-gradient(top, #a8cbe6 0%, #6c9dc0 85%, #628faf 100%);
            background-image: linear-gradient(top, #93bad8 0%, #6c9dc0 85%, #628faf 100%);
            box-shadow: 0 -2px 2px rgba(0, 0, 0, 0.1), 0 1px rgba(255, 255, 255, 0.35) inset;
            z-index: 10;
            font: bold 14px sans-serif;
            color: #3e769e;  text-align: center;
            text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.62);
            line-height: 115px;
          }
          .back {
            background-image: -webkit-linear-gradient(top, #93bad8 0%, #89afcc 10%, #5985a5 60%);
            background-image: -moz-linear-gradient(top, #93bad8 0%, #89afcc 10%, #5985a5 60%);
            background-image: linear-gradient(top, #93bad8 0%, #89afcc 10%, #5985a5 60%);
            border-radius: 0 5px 0 0;
            box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.15);
            &:before {
              content: '';
              width: 60px;
              height: 10px;
              border-radius: 4px 4px 0 0;
              background-color: #93bad8;
              position: absolute;
              top: -10px;
              left: 0px;
              box-shadow: 0 -1px 1px rgba(0, 0, 0, 0.15);
            }
            &:after {
              content: '';
              width: 100%;
              height: 4px;
              border-radius: 5px;
              position: absolute;
              bottom: 5px;
              left: 0px;
              box-shadow: 0 4px 8px #333;
            }
          }
          &:hover .front {
            -moz-transform: rotateX(-50deg);
            -webkit-transform: rotateX(-50deg);
            transform: rotateX(-50deg);
          }
        }
        a:hover {
          text-decoration: none;
        }
        .info {
          position: absolute;
          left: 7px;
          top: 0px;
          padding: 4px 1px 0px 3px;
          background: white;
          border-radius: 50% !important;
          font-size: 20px;
          a {
            color: #6089a7;
            -webkit-transition: all 0.3s;
            -moz-transition: all 0.3s;
            -ms-transition: all 0.3s;
            -o-transition: all 0.3s;
            transition: all 0.3s;
            &:hover{
              color: #8eb2cc;
            }
          }
        }
        .info_open {
          position: absolute;
          left: 3%;
          top: 3%;
          width: 94%;
          height: 94%;
          opacity: 0.98;
          background: rgba(247, 247, 247, 0.9);
          z-index: 99;
          display: none;
          .text {
            opacity: 1;
            color: #738998;
            z-index: 9999;
            font-size: 13px;
            font-weight: bold;
            padding: 24px 10px 0 10px;
            text-align: center;
            line-height: 34px;
          }
        }
        .info_btnClose {
          position: absolute;
          left: 9px;
          top: 7px;
          color: gray;
          z-index: 999;
          display: none;
          .close {
            height: 20px;
            width: 20px;
            margin-left: 0px;
            text-indent: 0;
            background: none !important;
          }
        }
      }
      .activeThumb {
        border: 2px solid #428bca;
        &:hover {
          border: 2px solid #428bca !important;
        }
      }
      .thumbnail {
        .image {
          height: 121px;
          margin: 19px 0 0 0;
          text-align: center;
          .typeFile {
            position: absolute;
            top: 0px;
            right: 0px;
            font-size: 12px;
            color: #626262;
            font-weight: bold;
            background: #ddd;
            padding: 0px 6px 2px 6px;
            opacity: 1;
          }
        }
        .folder {
          cursor: pointer;
          &:hover {}
          .folderClose {
            width: 110px !important;
            display: inline-block;
            margin-top: -20px;
          }
          .folderOpen {
            width: 110px !important;
            display: none;
            margin-top: -20px;
          }
        }
        a {
          &:hover {
            color: #626262;
            font-weight: normal;
          }
          color: #626262;
          font-weight: normal;
        }
        img {
          max-height: 120px;
          width: auto !important;
          width: 100%;
          max-width: 100%;
          cursor: pointer;
        }
        &:hover {
          border: 1px solid #428bca;
        }
      }
      .options {
        .selectedImg {
          background: url( '../images/file-manager/checkbox7_checked.png ') no-repeat bottom left !important;
          float: left;
          height: 42px;
          width: 43px;
          cursor: pointer;
          margin: -2px 0 0 -14px !important;
        }
        .checkbox {
          background: url( '../images/file-manager/checkbox7.png ') no-repeat bottom left;
          float: left;
          height: 42px;
          width: 43px;
          cursor: pointer;
          margin: 1px 0 0 -16px !important;
        }
        .checkImg {
          display: none;
        }
        .inputEditCredit, .inputEditName {
          width: calc(100% - 42px);
          border: 1px solid white;
          padding: 3px 5px;
          max-width: 80%;
          float: right;
          font-size: 14px;
          margin-top: 3px;
          transition: border linear 0.2s, box-shadow linear 0.2s;
          -webkit-box-shadow: none;
          -moz-box-shadow: none;
          box-shadow: none;
          outline: none;
        }
        .inputEditCredit:hover, .inputEditName:hover {
          border: 1px dashed #B7B7B7;
        }
        .inputEditCredit:focus, .inputEditName:focus {
          border: 1px solid #B7B7B7;
        }
      }
      .bottom {
        background: #e3e3e3;
        height: 20px;
        width: 100%;
        padding-right: 10px;
      }
      .options .disableInput {
        background: none !important;
        border: 1px dashed rgba(0, 0, 0, 0) !important;
        box-shadow: 0 0px 0px rgba(0, 0, 0, 0) inset !important;
      }
    }
    .chooseOneFile {
      position: absolute;
      top: 4px;
      left: 50%;
      .btn {
        position: relative;
        left: -50%;
        padding: 4px 8px !important;
        font-size: 12px;
        font-weight: bold;
        border-radius: 3px !important;
      }
    }
  }
}

@media (max-width: 560px) {
  .modImages {
    .path {
      min-height: 80px;
    }
    ul {
      -webkit-padding-start: 0px;
      margin-bottom: 0 !important;
      @include  margin-right(122px);
    }
    .buttons {
      width: 116px;
      .first {
        margin-bottom: 5px !important;
      }
    }
  }
}

#file-manager-modal {
  display: block;
  background: #fff;
  height: 90vh;
  width: 1024px;
  margin: 0 auto;
  .modal-title {
    color: #648aa9;
  }
  .modal-body {
    height: 93%;
    iframe {
      height: 95%;
      width: 100%;
    }
  }
}

#fileupload {
  .text-danger {
    color: #fff;
  }
}

#files-crop {
  .mainCutImg {
    display: inline-block;
    border: 2px solid #000000;
    margin:5px 5px 0 0;
  }

  .beforeImgBox, .afterImgBox
  {
    width: 250px;
    margin-bottom: 10px;
    text-align: right;
  }
  .beforeImgBox .imgBlock
  {
    border: 2px solid #000000;
    height: 250px;
    width: 250px;
    box-sizing: initial;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAdSURBVChTYziDAf5jgKGrCEojAahaJDBEFZ05AwDtzYNYOoLD6AAAAABJRU5ErkJggg==);
  }
  .beforeImgBox .imgBlock img
  {
    max-height: 250px;
    max-width: 250px;
  }

  .afterImgBox .imgBlock
  {
    border: 2px solid #000000;
    height: 250px;
    width: 250px;
    box-sizing: initial;
    display: table-cell;
    vertical-align: middle;
    text-align: center;
    background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAdSURBVChTYziDAf5jgKGrCEojAahaJDBEFZ05AwDtzYNYOoLD6AAAAABJRU5ErkJggg==);
  }

  .afterImgBox .imgBlock div
  {
    margin: auto;
    direction: ltr;
    overflow: hidden;
  }

  /* Old Vertions: */

  .oldImagesBlock{
    background: rgb(220, 220, 220);
    margin-top: 20px;
    padding:0 10px;
  }

  .noOldYet{
    margin-right: 30px;
    margin-bottom: 30px;
  }


  .oldImageBox .options {
    position: absolute;
    left: 0;
    top: 0;
  }

  .oldImageBox .imgBlock {
    width: 250px;
    height: 250px;
    display: table-cell;
    text-align: center;
    vertical-align: middle;
  }

  .oldImageBox .imgBlock img {
    max-width: 250px;
    max-height: 250px;
  }
}

.file-multi-container {
  .ui-state-highlight {
    border: 1px dotted #dddddd;
    height: 294px;
    display: inline-block;
    vertical-align: top;
    margin: 10px 10px 10px 10px;
    width: 213px;
    border-radius: 3px;
  }
  .file-multi-options {
    button {
      display: inline;
      border: 2px solid #3d4a53;
      border-radius: 9px;
      margin: 0px 2px 10px 0;
      color: #3d4a53;
      -webkit-transition: all 0.3s;
      transition: all 0.3s;
      background: #fff;
      font-size: 14px;
      padding: 1px 5px;
      font-weight: 700;
      &:hover {
        border: 2px solid #988ee6;
        color: #988ee6;
      }
      &:focus {
        outline: 0 !important;
      }
      &.red-button {
        &:hover {
          border: 2px solid #d05e5e;
          color: #d05e5e;
        }
      }
    }
  }
}

.fileinput {
  margin: 5px;
  min-width: 188px;
  &.multi {
    cursor: move;
  }
  .thumbnail {
    width: 100%; height: 100%;
    position: relative;
    .options {
      position: absolute;
      top: 10px;
      left: 10px;
      ul {
        list-style-type: none;
        margin: 0;
        font-size: 17px;
        padding: 0;
        li {
          cursor: pointer;
          display: inline;
          float: left;
          border: 2px solid #3d4a53;
          border-radius: 9px;
          margin: 0 2px;
          padding: 0 2px;
          color: #3d4a53;
          -webkit-transition: all 0.3s;
          -moz-transition: all 0.3s;
          -ms-transition: all 0.3s;
          -o-transition: all 0.3s;
          transition: all 0.3s;
          &.remove-red {
            &:hover {
              border: 2px solid #d05e5e;
              color: #d05e5e;
            }
          }
          &:hover {
            border: 2px solid #988ee6;
            color: #988ee6;
          }
        }
      }
    }
    .image-input-placeholder {
      width: 120px;
      height: 120px;
      border-radius: 3px;
      margin: 40px auto 10px auto;
    }
  }
}
