.c_login {
  background: #26344B url("../../admin/images/back-lr.png") no-repeat;
  background-size: cover;
  height: 100vh !important;
  overflow-y: hidden;

  .page-logo img {
    width: 80px;
  }

  .page-header.navbar .page-logo .logo-default {
    margin: 10px 0 0 34px;
  }

  .events  table.table-bordered.dataTable tbody th, table.table-bordered.dataTable tbody td {
    max-width: 250px !important;
  }

  .page-lock {
    width: 380px;
    height: 450px;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    margin: auto;
  }

  .page-lock .lock-form .form-group .form-control {
    color: #98A7BD;
  }

  .page-lock .lock-avatar-block {
    margin-left: 20px;
  }

  .page-lock .page-logo {
    text-align: center;
  }
  .page-lock .page-logo  img {
    height: 160px;
  }

  .page-lock .page-body {
    width: 100%;
    margin-top: 35px;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    -ms-border-radius: 7px;
    -o-border-radius: 7px;
    border-radius: 7px;
  }

  .page-lock .lock-head {
    display: block;
    text-align: left;
    padding-top: 15px;
    //padding-left: 35px;
    padding-bottom: 15px;
    margin-bottom: -30px;
    font-size: 22px;
    font-weight: 400;
    color: #4db3a5;
    -webkit-border-radius: 7px 7px 0 0;
    -moz-border-radius: 7px 7px 0 0;
    -ms-border-radius: 7px 7px 0 0;
    -o-border-radius: 7px 7px 0 0;
    border-radius: 7px 7px 0 0;

    img {
      width: 140px;
      margin: 0 auto;
      display: block;
    }
  }

  .sphere-login {
    background-color: #00cde1;
    color: #fff;
  }

  .loginFooter img {
  }

  .page-lock .lock-body {
    display: block;
    margin: 35px;
    overflow: hidden;

    form {
      width: 100%;
    }
  }

  .page-lock .lock-avatar {
    margin-top: 15px;
    height: 110px;
    -webkit-border-radius: 50% !important;
    -moz-border-radius: 50% !important;
    border-radius: 50% !important;
  }

  .page-lock .lock-form h4 {
    margin-top: 0px;
    color: #dbe2ea;
    font-size: 18px;
    font-weight: 400;
  }

  .page-lock .lock-form .form-group {
    margin-top: 20px;
  }

  .page-lock .lock-form .form-group .form-control {
    background-color: #f5fcff;
    border: none;
    text-align: center;
    font-size: 16px;
    height: 40px;
    color: #555555;
    font-weight: 500;
  }

  .loginFooter {
    position: absolute;
    bottom: 20px;
    left: 20px;
    z-index: 1;
    color: #fff;
  }

  .loginFooter img {
    border-right: 2px solid #434850;
    padding-right: 10px;
    margin-right: 10px;
  }

  .loginFooter span {
    color: #7b7d81;
  }

  .page-lock .lock-form .form-group .form-control::-moz-placeholder {
    color: #556376;
    opacity: 1;
  }

  .page-lock .lock-form .form-group .form-control:-ms-input-placeholder {
    color: #556376;
  }

  .page-lock .lock-form .form-group .form-control::-webkit-input-placeholder {
    color: #556376;
  }

  .page-lock .lock-form .form-group .form-control:focus {
    background-color: #f5fcff;
  }

  .page-lock .lock-form .form-actions {
    margin-top: 20px;
  }

  .page-lock .lock-form .form-actions .btn {
    width: 100%;
    font-weight: 600;
    padding: 10px;
    transition: all 0.4s;
  }

  .page-lock .lock-form .form-actions .btn:hover {
    background-color: rgba(67, 181, 180, 0.64);
    color: #fff;
  }

  .page-lock .lock-bottom {
    display: block;
    background-color: #323d4b;
    text-align: center;
    padding-top: 20px;
    color: #98A7BD;
    padding-bottom: 20px;
    -webkit-border-radius: 0 0 7px 7px;
    -moz-border-radius: 0 0 7px 7px;
    -ms-border-radius: 0 0 7px 7px;
    -o-border-radius: 0 0 7px 7px;
    border-radius: 0 0 7px 7px;
  }

  .page-lock .lock-bottom a {
    font-size: 14px;
    font-weight: 400;
    color: #638cac;
  }

  .page-lock .lock-bottom a:hover {
    color: #7ba2c0;
  }

  .page-lock .page-footer-custom {
    text-align: center;
    padding-top: 40px;
    color: #5f6e83;
  }

  .page-footer-inner a {
    color: #fff;
    font-weight: bolder;
  }

  .brand img {
    width: auto;
  }


  .mainBanner input {
    margin-bottom: 10px;
  }

  .compie-alerts {
    margin-top: 20px;
  }

  .compie-alerts .compieAlert {
    padding: 5px 10px;
    background-color: transparent;
    color: #fff;
    border: 1px solid #43b5b4;
    margin-bottom: 5px;
  }



  @media (max-width: 768px) {
    .page-lock {
      margin: 100px auto;
    }

    .page-header.navbar {
      background: #26344b;
    }
  }

  @media (max-width: 560px) {
    .page-lock  {
      margin: 50px auto;
      width: 400px;
    }
    .page-lock  .lock-body {
      margin: 30px;
    }
    .page-lock .lock-avatar {
      width: 120px;
      height: 120px;
      margin-top: 20px;
    }
    .page-lock .lock-form h4 {
      font-size: 16px;
    }

    .page-lock .lock-form .form-group .form-control {
      padding: 20px 20px;
      width: 170px;
    }

    .page-lock .lock-form .form-actions .btn-success {
      width: 170px;
      margin-bottom: 10px;
    }
  }

  @media (max-width: 420px) {
    .page-lock {
      margin: 30px auto;
      width: 280px;
    }
    .page-lock .lock-body {
      margin: 0px;
    }
    .page-lock .page-body {
      margin-top: 30px;
    }
    .page-lock .lock-avatar {
      margin-top: 20px;
    }
    .page-lock .lock-avatar-block {
      display: block;
      width: 100%;
      text-align: center;
      margin-bottom: 10px;
    }
    .page-lock .lock-form {
      padding-left: 20px;
    }
    .page-lock .lock-form h4 {
      font-size: 16px;
      text-align: center;
    }
    .page-lock .lock-form .form-group .form-control {
      padding: 20px 20px;
      width: 240px;
    }
    .page-lock .lock-form .form-actions .btn-success {
      width: 240px;
      margin-bottom: 20px;
    }
  }

}